.app {
	position: relative;
	min-height: 100vh;

	& > .layout {
		position: relative;
		padding-top: 60px;
		min-height: 100vh;
		overflow: hidden;

		@media screen and (--mobile) {
			padding-top: 56px;
		}

		&.without-padding-top {
			padding-top: 0;
		}
	}
}
