/*
 * This is for global css usage.
 */

*,
*::after,
*::before {
	box-sizing: border-box;
	font-family: 'Noto Sans TC', 'PingFangTC-Light', 'Microsoft JhengHei', sans-serif;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	outline: none;
	color: var(--black);
}

/* 阻止圖片右鍵下載 */
img {
	pointer-events: none;
}

/* 調整 Quill Editor 編輯器在顏色、B、I、U 同時設定時，無法正常顯示顏色 */
[class*='index__editor-content'] a > strong,
[class*='index__editor-content'] a > s,
[class*='index__editor-content'] a > em,
[class*='index__editor-content'] a > u,
[class*='index__editor-content'] a > strong {
	color: inherit !important;
}

body,
input,
select,
textarea,
button {
	-webkit-font-smoothing: antialiased;
	font-family: 'Noto Sans TC', 'Roboto', 'PingFangTC-Light', 'Microsoft JhengHei', sans-serif;
	padding: 0;
	border: none;
	background-color: transparent;
	border-radius: 0;
	/* stylelint-disable-next-line property-no-vendor-prefix */
	-webkit-appearance: none;
}

body {
	margin: 0;
	overflow-x: hidden;
	width: 100%;
	background-color: var(--bgGray);

	&:global(.no-scroll) {
		position: fixed;
		overflow-y: scroll;
	}
}

button {
	padding: 0;
	border: none;
	background-color: transparent;
	cursor: pointer;
}

a {
	text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin: 0;
	padding: 0;
}

li {
	list-style: none;
}

ol,
ul {
	margin: 0;
	padding: 0;
}

:global(.swiper-slide) {
	/* stylelint-disable-next-line property-no-vendor-prefix */
	-webkit-transform: translateZ(0);
	/* stylelint-disable-next-line property-no-vendor-prefix */
	-webkit-backface-visibility: hidden;
}
